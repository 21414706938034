<template>
  <div class="dark-bg">
    <bread-crumb></bread-crumb>
    <div class="ip-telephony">
      <div
        class="ip-telephony__descr"
        v-if="description"
        v-html="description"
      ></div>
      <div class="ip-telephony__wrap fs28">
        <div class="cart__wrap container container__mini">
          <Cart
            v-for="children in childrens"
            :data="children"
            :key="children.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
const Cart = defineAsyncComponent(() => import("@/components/Cart.vue"));
import axios from "axios";

export default {
  components: {
    Cart,
    BreadCrumb,
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      description: "",
      childrens: [],
      data: [],
    };
  },
  methods: {
    async getService() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/services/search/" +
            clear_url,
          config
        );
        this.description = response.data.data[0].description;
        this.childrens = response.data.data[0].children;
        this.data = response.data.data[0];
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getService();
  },
  updated() {
    document.title = this.data.name + " - iIT Trading";
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.ip-telephony {
  &__descr {
    max-width: 980px;
    padding: 0 15px;
    margin: 0 auto;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 50, 25);
    line-height: calc(25 / 16);
    color: #fff;
  }

  &__wrap {
    @include property("padding-top", 150, 50);
    @include property("padding-bottom", 150, 50);

    @media (max-width: 500px) {
      padding-right: 20px;
    }
  }
}

.cart {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 767.98px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.informational-security {
  display: flex;
  flex-direction: column;
  @include property("margin-bottom", 150, 30);
  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 48, 25);
  }

  &__subtitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    @include property("font-size", 28, 21);
    line-height: calc(40 / 28);
    color: #f9b80e;
    @include property("margin-bottom", 60, 30);

    img,
    v-lazy-image {
      display: block;
      flex: 0 0 26px;
      margin-right: 15px;
    }
  }

  &__wrap {
    display: grid;
    @include property("gap", 65, 20, true, 1670, 1024);
    @include property("margin-bottom", 45, 15);
    justify-content: center;

    @media (min-width: 768px) {
      &:not(.reverse) {
        grid-template-columns: 1.5fr 1fr;
      }

      &.reverse {
        grid-template-columns: 1fr 1.5fr;
      }
    }

    &--img {
      background: url("~@/assets/images/bg-card.jpg") no-repeat;
      background-size: cover;
      width: 100%;
      align-self: flex-start;

      .reverse & {
        order: 2;
      }

      @media (max-width: 767.98px) {
        max-width: 500px;
        margin: 0 auto;
      }

      &--inner {
        position: relative;
        padding-bottom: 76%;
      }

      img {
        position: absolute;

        .service__wrap:not(.reverse) & {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          max-height: 239px;
          max-width: 239px;
        }

        .reverse & {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--descr {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--title {
        @include property("font-size", 24, 21);
        letter-spacing: 0.48px;

        span {
          color: #f9b80e;
        }
      }

      &--text {
        @include property("font-size", 24, 20);
        letter-spacing: 0.48px;
        color: #1c1c1c;
      }
    }
  }
}
</style>
